<template>
  <b-container fluid>
    <div class="d-flex flex-column align-items-center p-5">
      <div v-if="item" class="text-center w-75 mb-5">
        <h4>{{ item.title }}</h4>
        <div class="row align-items-center justify-content-center my-3">
          <b-icon icon="clock" scale="1.2" variant="info"></b-icon>
          <h6 class="text-grey mr-2 mb-0">
            {{ new Date(item.created_at).toISOString().slice(0, 10) }}
          </h6>
        </div>
        <img :src="item.image" class="w-75" height="420" />
        <div
          class="my-5"
          style="line-height: 1.5 !important"
          v-html="item.content"
        ></div>
      </div>
      <VLatestBlogs
        v-if="list != null && list.length > 0"
        :list="list"
        title="أحدث المقالات"
        :categories="1"
      ></VLatestBlogs>
    </div>
  </b-container>
</template>

<script>
import { errorAlert } from "@/utils/helper";
export default {
  mounted() {
    this.get();
  },
  computed: {
    list() {
      var list = this.$store.state.website.articles.list;
      const item = list.find((item) => item.id === this.item.id);
      list.splice(list.indexOf(item), 1);
      return list;
    },
    item() {
      return this.$store.state.website.articles.form;
    },
  },
  watch: {
    $route() {
      this.get();
    },
  },
  methods: {
    get() {
      this.getList();
      this.getArticle(this.$route.params.id);
    },
    getList() {
      this.$store.dispatch("website/articles/get");
    },
    getArticle(item) {
      this.$store.dispatch("website/articles/getByID", item).catch(() => {
        errorAlert();
      });
    },
  },
};
</script>

<style></style>
